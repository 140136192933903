export const PROJECTS = [
  {
    id: "1",
    title: "Lanscape Website",
    description:
      "Portfolio website for a landscape company. Created with React and has admin panel with Sanity",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1729154122/ngsktnr2nssgfomegemk.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://gurkanbotanik.com/"
    },
    technologies: ["#react", "#javascript", "#sanity"],
  },
  {
    id: "2",
    title: "Freshdirect",
    description:
      "Shopping app in USA that belongs to Getir. Developed by React Native. Mainly worked on accesibility issues.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1729154403/m78a1aeut7vt68c35mgr.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://www.freshdirect.com/",
      android: "https://play.google.com/store/apps/details?id=com.freshdirect.android&hl=tr",
      iphone: "https://apps.apple.com/us/app/freshdirect-grocery-delivery/id346631494",
    },
    technologies: ["#typescript", "#react-native"],
  },
  {
    id: "3",
    title: "Omnimessenger Admin Panel & App",
    description:
      "Automated messaging service. Developed website with Vanilla JS & App with React Native",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1729157000/uhqbg6tzyvwmcjkru7az.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://www.omnimessenger.app/"
    },
    technologies: ["#javascript", "#react-native", "#react"],
  },
  {
    id: "4",
    title: "Botgate Admin Panel & Website",
    description:
      "Marketing chatbot enhanced with AI. Admin panel developed with React.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1729155173/hgzyooaszmbjaqclqxkd.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://www.botgate.ai/",
    },
    technologies: ["#typescript", "#react"],
  },
  {
    id: "5",
    title: "Guess Who?",
    description:
      "App that challenges users to guess the correct person based on a set of hints, presented alongside five photos. Designed & Developed by myself from scratch.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1685339488/AppIcon_ios-marketing_jce3jt.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      repo: "https://github.com/egecetiner/guessWho",
      android: "https://play.google.com/store/apps/details?id=com.guesswhoami",
      iphone: "https://apps.apple.com/nz/app/guess-who/id6448758473",
    },
    technologies: ["#typescript", "#react-native", "#firebase"],
  },
  {
    id: "6",
    title: "Ticket Selling",
    description:
      "It is an Turkish payment system Iyzico implemented (just like Stripe) ticket selling platform.",
    image: {
      src: "https://res.cloudinary.com/dx6tl6aa2/image/upload/v1671564532/portfolio/projects/Syntho/Screenshot_2022-12-20_at_21.26.17_1_c59hzo.jpg",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      repo: "https://github.com/egecetiner/rn-ticketSelling",
    },
    technologies: ["#typescript", "#react-native", "#mobx", "#firebase"],
  },
  {
    id: "7",
    title: "Ikea Turkey",
    description:
      "It is an e-commerce mobile app. I worked in new campaigns flows.",
    image: {
      src: "https://cdn.ikea.com.tr/app/mobil-uygulama/ikea-place.jpg",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://apps.apple.com/tr/app/ikea-mobil/id1147147196",
    },
    technologies: ["#rollo", "#javascript", "#html", "#css", "#restapi"],
  },
  {
    id: "8",
    title: "Ziraat Bank OPI Turkey",
    description:
      "It is banking app for bulk transactions. I implemented OTP service, dealt with bugs and some changes in mobile application.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675120615/oblozhka-1_npevfm_ultltx.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://apps.apple.com/tr/app/ziraat-bankas%C4%B1-opi/id1480541290",
    },
    technologies: [
      "#typescript",
      "#react-native",
      "#materialui",
      "#formik",
      "#restapi",
    ],
  },
  {
    id: "9",
    title: "Ziraat Bank Agriculture Turkey",
    description:
      "It is agricultural product trading mobile application and has not been released yet. We were developing login and advertise flows from scratch.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675118632/OGB-MobileApp-Blog-01_x89dad.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {},
    technologies: ["#react-native", "#redux", "#formik", "#restapi"],
  },
  {
    id: "10",
    title: "Yuffi",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675118650/on-demand-baby-sitter-app_wxxts5.jpg",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://play.google.com/store/apps/details?id=com.yuffiapp",
    },
    description:
      "It is babysitter finder app. I was dealing with design and logic fixes/bugs and also implemented iyzico payment system.",
    technologies: ["#react-native", "#restapi", "#redux"],
  },
  {
    id: "11",
    title: "OdeaBank Turkey",
    description:
      "It is banking app. I was dealing with fixes and bugs requested by the client. The hardest fix I remember was in crypto flows. Banking projects helped me to understand security issues.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675119203/mobile-banking-hero-phone-image-v2_vm9rrh.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://apps.apple.com/tr/app/odeabank/id634414038?l=tr",
    },
    technologies: ["#rollo", "#javascript", "#html", "#css"],
  },
  {
    id: "12",
    title: "HSBC Bank Turkey",
    description:
      "It is banking app. I was dealing with fixes and bugs requested by the client. Banking projects helped me to understand security issues.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675118719/602d3abef69d9a6993226f88_Frame_2_yfehew.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://apps.apple.com/tr/app/hsbc-t%C3%BCrkiye/id1339127709?l=tr",
    },
    technologies: ["#rollo", "#javascript", "#html", "#css"],
  },
  {
    id: "13",
    title: "Eurasia Tunnel",
    description:
      "It is mobile app to pay tolls through the Eurasia Tunnel. I implemented the design of new upcoming campaigns and took part in connecting the services.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675119292/135_m4rnmn.jpg",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://apps.apple.com/tr/app/avrasya-t%C3%BCneli/id1455842002?l=tr",
    },
    technologies: [
      "#react-native",
      "#typescript",
      "#material-ui",
      "#styled-components",
    ],
  },
  {
    id: "14",
    title: "Medical To Life",
    description:
      "This project has not been released yet. Me and my friend developed content upload web panel (for the app that is not developed by us) with react typescript and firebase from scratch. It has stripe payment system for users and chat between admins in the web panel and users in the app.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675118514/Screen_Shot_2023-01-31_at_01.21.13_csapex.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {},
    technologies: [
      "#react",
      "#typescript",
      "#material-ui",
      "#firebase",
      "#firestore",
    ],
  },
  {
    id: "15",
    title: "Foodtech Simple Kitchen",
    description:
      "It is food deliver mobile app. I was dealing with fixes and bugs requested by the client.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675120426/Simulator_Screen_Shot_-_iPhone_12_-_2023-01-31_at_01.59.21_ucnofm.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://play.google.com/store/apps/details?id=com.simplekitchen",
    },
    technologies: [
      "#react-native",
      "#typescript",
      "#material-ui",
      "#styled-components",
      "#antdesign",
    ],
  },
  {
    id: "16",
    title: "Kitchen Labs",
    description:
      "It is static website of the food deliver company. I was dealing with fixes and bugs requested by the client.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675119652/Screen_Shot_2023-01-31_at_02.00.44_vycjem.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://kitchenlabs.com.tr",
    },
    technologies: [
      "#react",
      "#typescript",
      "#material-ui",
      "#styled-components",
    ],
  },
  {
    id: "17",
    title: "Foodtech Simple Kitchen Panel",
    description:
      "It is admin panel for the food deliver app. I was dealing with fixes and bugs requested by the client.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675120210/Screen_Shot_2023-01-29_at_04.33.09_zgina2.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://foodtech.codeventure.co/login",
    },
    technologies: [
      "#react",
      "#typescript",
      "#material-ui",
      "#styled-components",
    ],
  },
  {
    id: "18",
    title: "Foodtech Simple Kitchen Web",
    description:
      "It is website to register the food deliver app. I was dealing with fixes and bugs requested by the client.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675120831/Screen_Shot_2023-01-30_at_03.43.50_bwgukt.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://simplekitchen.com.tr/",
    },
    technologies: [
      "#react",
      "#nextjs",
      "#typescript",
      "#material-ui",
      "#styled-components",
    ],
  },
  {
    id: "19",
    title: "El Poder Del Amor - Kısmetse Olur",
    description:
      "It is an app for the TV show. You can rate the contestants and watch the past episodes. we recently started adding dating app features.",
    image: {
      src: "https://res.cloudinary.com/dgdihdn5o/image/upload/v1675120091/Simulator_Screen_Shot_-_iPhone_13_-_2023-01-31_at_02.06.19_wzjrkb.png",
      placeholderSrc:
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==",
    },
    links: {
      site: "https://apps.apple.com/us/app/el-poder-del-amor/id1629460871",
    },
    technologies: [
      "#react-native",
      "#typescript",
      "#material-ui",
      "#styled-components",
    ],
  },
];
